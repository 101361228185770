import React, { useEffect, useState } from "react";
import supabase from "./supabase";

import "./style.css";

const CATEGORIEN = [
  { name: "ontbijt", color: "#3b82f6" },
  { name: "lunch", color: "#16a34a" },
  { name: "snacks", color: "#ef4444" },
  { name: "soep", color: "#eb9959" },
  { name: "avondeten", color: "#eab308" },
  { name: "dessert", color: "#db2777" },
];

const VEGA = [
  { name: "vegan", color: "#9999f4" },
  { name: "vegetarisch", color: "#4ca64c" },
  { name: "vlees", color: "#cc8400" },
  { name: "alcohol", color: "#0000ff" },
];

function App() {
  const [showForm, setShowForm] = useState(false)
  const [recepten, setRecepten] = useState([]);
  const [huidigeCategorie, setHuidigeCategorie] = useState("alles");

  useEffect(function () {
    async function getRecepten() {
      
      let query = supabase.from("recepten")
      .select("*")
      .order("favoriet", {ascending:false});
      
      if (huidigeCategorie !== "alles") {
        query = query.eq("categorie", huidigeCategorie)
      }
      
      const { data: recepten, error } = await query

      setRecepten(recepten);
    }
    getRecepten();
  }, [huidigeCategorie]);

  return (
    <>
      <PaginaHeader showForm={showForm} setShowForm={setShowForm}/>
      {showForm ? <NieuwReceptForm setRecepten={setRecepten} /> : null }
      <main className="main">
        <CategorieFilter setHuidigeCategorie={setHuidigeCategorie} />
        <ReceptLijst recepten={recepten} />
      </main>
    </>
  );
}

function PaginaHeader({ showForm, setShowForm }) {
  return (
    <header className="header">
      <div className="logo">
        <img
          src="logo.png"
          height="40px"
          width="40px"
          alt="Logo van het receptportaal"
        />
        <h1>Receptenportaal</h1>
      </div>

      <button className="btn btn-groot" onClick={() => setShowForm((show) => !show)}>{showForm ? "Sluiten" : "Nieuw Recept"}</button>
    </header>
  );
}

function NieuwReceptForm({ setRecepten }) {
  const [text, setText] = useState("");
  const [bron, setBron] = useState("");
  const [categorie, setCategorie] = useState("");
  const [vega, setVega] = useState("");
  const [favoriet, setFavoriet] = useState(false);
  const [comments, setComments] = useState("");


  async function handleSubmit(e) {
    // 1. Prevent browser reload
    e.preventDefault();

    // 2. Check if the data is valid. If so create new recept
    if (text && categorie) {

      // 3. upload recept to supabase and receive the new object
      const {data: newRecept, error} = await supabase.from("recepten").insert([{text, bron, categorie, vega, comments}]).select()

      // 4. Add new recept to UI (to state)
      setRecepten((recepten) => [newRecept[0], ...recepten]);
      // 5. Reset input fields
      setText("");
      setBron("");
      setCategorie("");
      setVega("");
      setComments("")
      // 6. Close the form
    }
  }

  return (
    <form  onSubmit={handleSubmit}>
      <div className="recept-formulier"> 
      {" "}
      <input
        type="text"
        placeholder="Omschrijving van het gerecht"
        value={text}
        onChange={(e) => setText(e.target.value)}
      />
      <input
        value={bron}
        type="text"
        placeholder="Link naar het recept"
        onChange={(e) => setBron(e.target.value)}
      />
      
      <select value={categorie} onChange={(e) => setCategorie(e.target.value)}>
        <option value="">Kies een categorie</option>
        {CATEGORIEN.map((cat) => (
          <option key={cat.name} value={cat.name}>
            {cat.name.toUpperCase()}
          </option>
        ))}
      </select>
      
      <select value={vega} onChange={(e) => setVega(e.target.value)}>
        <option value="">Kies een type</option>
        {VEGA.map((cat) => (
          <option key={cat.name} value={cat.name}>
            {cat.name.toUpperCase()}
          </option>
        ))}
      </select>
      
      
      </div>
      <div className="comments">
      <input
        type="text"
        placeholder="Opmerkingen bij het recept"
        value={comments}
        onChange={(e) => setComments(e.target.value)}
      />
      <button className="btn btn-groot">Verstuur</button>
      </div>
    </form>
  );
}

function CategorieFilter({setHuidigeCategorie}) {
  return (
    <aside>
      {" "}
      <ul>
        <li>
          <button
            className="btn btn-alle-categorien"
            onClick={() => setHuidigeCategorie("alles")}
          >
            Alles
          </button>
        </li>

        {CATEGORIEN.map((cat) => (
          <li key={cat.name} className="categorie">
            <button
              className="btn btn-catgorie"
              style={{ backgroundColor: cat.color }}
              onClick={() => setHuidigeCategorie(cat.name)}
            >
              {cat.name}
            </button>
          </li>
        ))}
      </ul>
    </aside>
  );
}

function ReceptLijst({ recepten }) {
  
  if(recepten.length === 0) {
    return <p className="message">Er zijn geen recepten voor deze categorie.</p>
  }
  
  return (
    <section>
      <ul>
        {recepten.map((recept) => (
          <Recept key={recept.id} recept={recept} />
        ))}
      </ul>
      <p>Er zijn {recepten.length} recepten in de database. </p>
    </section>
  );
}

function Recept({ recept }) {

  const [showFav, setShowFav] = useState(bepaalFavoriet());
  
  function bepaalFavoriet () {
  const favoriet = recept["favoriet"] ? "vote-button-fav" : "vote-button"
  return favoriet
  }
  
  function flipFavoriet () {
    const favoriet = recept["favoriet"] ? "vote-button" : "vote-button-fav"
    return favoriet
    }
  
  async function handleVote(favoriet) {
    
    if(recept[favoriet]){
    
    const { data: updatedRecept, error} = await supabase
    .from("recepten")
    .update({"favoriet" : false})
    .eq("id", recept.id)
    .select();
  } else {
    const { data: updatedRecept, error} = await supabase
    .from("recepten")
    .update({"favoriet" : true})
    .eq("id", recept.id)
    .select();
  }}
  
  let bronText
  if (recept.bron !== "") {
    bronText = <a
    className="source"
    href={recept.bron}n
    target="_blank"
    rel="noreferrer"
  >
    (Recept)
  </a>
  } else {bronText = ""}
  
   
  return (
    <li key={recept.id} className="recept">
      <p>
        {recept.text}
        {bronText}
      </p>
      <span className="commentsRecept">
      <p>
      {recept.comments}
      </p>
      </span>
        <span>
        {recept.vega != null ?
        <span
          className="tag"
          style={{
            backgroundColor: VEGA.find(
              (veg) => veg.name === recept.vega)
              .color,
            }}
            >
          {recept.vega}
        </span> : null}
      <span
        className="tag"
        style={{
          backgroundColor: CATEGORIEN.find(
            (cat) => cat.name === recept.categorie
            ).color,
          }}
          >
        {recept.categorie}
      </span>
      <span className={showFav}>
        <button onClick={()=> {handleVote("favoriet"); setShowFav(flipFavoriet())}}>👍</button>
      </span>
      </span>
      {/* <div className="comments">
      {recept.comments}
      </div> */}
    </li>
  );
}

export default App;
